<template>
    <div class="enInfo">
        <TopbarSP/>
        <div class="main">
            <div class="form-wrap">
                <div class="title">您好，请完善公司信息</div>
                <el-form ref="ruleForm" :rules="rules" size="small" :hide-required-asterisk="true" :model="ruleForm" label-width="80px">
                    <el-form-item label="公司名称" prop="compName">
                        <el-input clearable v-model="ruleForm.compName" @focus="clearValiate('compName')"></el-input>
                    </el-form-item>
                    <div class="flex-item">
                        <el-form-item label="信用代码" prop="compNo">
                            <el-input clearable v-model="ruleForm.compNo" @focus="clearValiate('compNo')"></el-input>
                        </el-form-item>
                        <el-form-item label="所在城市" prop="areaIdArr">
                            <el-cascader
                                @focus="clearValiate('areaIdArr')"
                                clearable
                                :props="{ checkStrictly: true }"
                                placeholder=""
                                v-model="ruleForm.areaIdArr"
                                @change="areaChange"
                                :options="areaTree">
                            </el-cascader>
                        </el-form-item>
                    </div>
                    <div class="flex-item">
                        <el-form-item label="公司类型">
                            <el-select v-model="ruleForm.hrCompType" clearable placeholder="">
                                <el-option
                                    v-for="item in compTypeDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所属行业">
                            <el-select v-model="ruleForm.compIndustry" clearable placeholder="">
                                <el-option
                                    v-for="item in compIndustryDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="flex-item">
                        <el-form-item label="公司规模">
                            <el-select v-model="ruleForm.hrCompScale" clearable placeholder="">
                                <el-option
                                    v-for="item in compScaleDic"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="compEmail">
                            <el-input v-model="ruleForm.compEmail" clearable></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="公司地址"> 
                        <el-input v-model="ruleForm.compAddress" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="公司简介">
                        <el-input clearable v-model="ruleForm.hrAbout" resize="none"  type="textarea" maxlength="1000" :autosize="{ minRows: 4, maxRows: 4}"></el-input>
                    </el-form-item>
                    <el-form-item prop="isAgree">
                        <div class="agree">
                            <el-checkbox v-model="ruleForm.isAgree"></el-checkbox>
                            <div class="detail">据相关法律要求，请确保您所提交的相关认证材料的真实性、合法性 有效性、授权目的包括但不限于注册、使用企业名下的招聘者账号。客服热线：0335-8511096</div>
                        </div>
                    </el-form-item>
                    <div class="btn-wrap">
                        <el-button class="build-btn" @click="createCcompany">创建公司</el-button>
                    </div>
                </el-form>
            </div>
        </div>
        <Footbar/>
    </div>
</template>

<script>
    import TopbarSP from "../../components/HumanResources/TopbarSP.vue";
    import Footbar from "../../components/HumanResources/Footbar.vue";
    export default {
        name:"EnterpriseInfo",
        components:{
            TopbarSP,
            Footbar
        },
        data(){
            // 信用代码校验
            const validateCompNo = (rule,value,callback) => {
                const reg=/^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/g;
                if(!reg.test(value)){
                    callback("请输入正确的信用代码");
                }else{
                    callback();
                }
            };
            // 邮箱校验
            const validateEmail=(rule,value,callback)=>{
                const reg=/^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
                if(!value){
                    callback();
                    return;
                }else if(!reg.test(value)){
                    callback("请输入正确的邮箱格式");
                    return;
                }
                callback();
            }
            // 校验是否同意
            const validateAgree=(rule,value,callback)=>{
                if(value===false){
                    callback("请阅读并勾选此项");
                }else if(value===true){
                    callback();
                }
            }
            return{
                // 表单数据
                ruleForm:{
                    adminId:"", // 用户id
                    areaIdArr:[], // 所在城市id（组件需要的数据类型）
                    areaId:"", // 所在城市
                    compAddress:"", // 公司(机构)地址
                    compEmail:"", // 公司邮箱
                    compIndustry:"", // 行业：字典表 [ INDUSTRY ]
                    compName:"", // 公司名称
                    compNo:"", // 信用代码
                    hrAbout:"", // 人才库公司简介
                    hrCompScale:"", // 人才库公司规模 [HR_COMP_SCALE]
                    hrCompType:"", // 人才库公司类型 [HR_COMP_TYPE]
                    isAgree:false
                },
                // 表单校验规则
                rules:{
                    compName:[
                        {required:true,message:"请输入公司名称",trigger:"blur"}
                    ],
                    compNo:[
                        {required:true,message:"请输入信用代码",trigger:"blur"},
                        {validator:validateCompNo,trigger:"blur"}
                    ],
                    areaIdArr:[
                        {required:true,message:"请选择所在城市",trigger:"change"},
                    ],
                    compEmail:[
                        {validator:validateEmail,trigger:"blur"}
                    ],
                    isAgree:[
                        {validator:validateAgree,trigger:"blur"}
                    ]
                },
                // 公司类型字典数据
                compTypeDic:[],
                // 公司规模字典数据
                compScaleDic:[],
                // 公司行业字典数据
                compIndustryDic:[],
                // 区划id数据
                areaTree:[]
            }
        },
        methods:{
            initData(){
                this.getCompTypeDic();
                this.getCompScaleDic();
                this.getCompIndustryDic();
                this.getAreaId();
                this.getUserId();
            },
            // 创建公司
            createCcompany(){
                const {ruleForm}=this;
                const params=JSON.parse(JSON.stringify(ruleForm));
                // 删除无用的入参
                delete params.areaIdArr;
                delete params.isAgree;
                this.$refs.ruleForm.validate(valid=>{
                    if(valid){
                        this.$post("/hr/saveCompany",params).then(()=>{
                            this.$router.push("/azhr/home");
                            this.$message.success("完善公司信息成功");
                        }).catch((e)=>{
                            console.error("创建公司出错",e);
                        });
                    }
                });
            },
            // 获取公司类型字典数据
            getCompTypeDic(){
                const obj=this.$setDictionary("HR_COMP_TYPE", "list")||{};
                const arr=[];
                Object.keys(obj).forEach(key=>{
                    arr.push({
                        label:obj[key],
                        value:key
                    });
                });
                this.compTypeDic=arr;
            },
            // 获取公司规模字典数据
            getCompScaleDic(){
                const obj=this.$setDictionary("HR_COMP_SCALE", "list");
                const arr=[];
                Object.keys(obj).forEach(key=>{
                    arr.push({
                        label:obj[key],
                        value:key
                    });
                });
                this.compScaleDic=arr;
            },
            // 获取所属行业字典数据
            getCompIndustryDic(){
                const obj=this.$setDictionary("INDUSTRY", "list");
                const arr=[];
                Object.keys(obj).forEach(key=>{
                    arr.push({
                        label:obj[key],
                        value:key
                    });
                });
                this.compIndustryDic=arr;
            },
            // 获取区划di
            getAreaId() {
                this.$post("/sys/area/tree").then((res) => {
                    this.areaTree = res.data || [];
                });
            },
            // 所在城市改变
            areaChange(arr){
                const {ruleForm}=this;
                if(arr.length<1){
                    // 没有选择地址
                    ruleForm.areaId="";
                }else{
                    // 如果选择了地址，取数组最后一位
                    ruleForm.areaId=arr.at(-1);
                }
            },
            // 获取用户id
            getUserId(){
                const userJson=JSON.parse(sessionStorage.getItem("userJson"));
                if(userJson.userId){
                    this.ruleForm.adminId=userJson.userId;
                }
            },
            // 清空表单某一项的校验结果
            clearValiate(key){
                this.$refs.ruleForm.clearValidate(key);
            }
        },
        created(){
            this.initData();
        }
    }
</script>

<style scoped lang="less">
    .enInfo{
        height: 100vh;
        overflow: hidden;
        .main{
            min-height: calc(100vh - 10.875rem);
            background-image: url("../../assets/humanResources/registerBackground.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: bottom center;
            display: flex;
            justify-content: center;
            align-items: center;
            .form-wrap{
                width: 54.125rem;
                height: 38rem;
                box-sizing: border-box;
                background: #FFFFFF;
                box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
                border-radius: 8px;
                padding: 2.75rem 6.625rem;
                .title{
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #333333;
                    text-align: center;
                }
                // 定义表单样式
                /deep/.el-form{
                    min-width: 31.25rem;
                    margin-top: 1.875rem;
                    .flex-item{
                        display: flex;
                        justify-content: space-between;
                        .el-form-item{
                            flex-grow: 1;
                        }
                    }
                    .el-form-item__label{
                        height: 2.25rem;
                        line-height: 2.25rem;
                    }
                    .el-input--small .el-input__inner{
                        height: 2.25rem;
                        line-height: 2.25rem;
                    }
                    .el-input__suffix{
                        line-height: 2.375rem;
                    }
                    .agree{
                        display: flex;
                        .el-checkbox{
                            margin-right: 3px;
                            margin-top: -0.4375rem;
                        }
                        .detail{
                            font-size: .75rem;
                            color: #DDDDDD;
                            line-height: normal;
                        }
                    }
                    // 创建按钮
                    .btn-wrap{
                        text-align: center;
                        margin-top: 2.25rem;
                        .build-btn{
                            width: 15.375rem;
                            border: none;
                            background: #2878FF;
                            border-radius: 2px;
                            font-size: .875rem;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
        // 滚动条整体部分
        ::-webkit-scrollbar {
            width:10px;
            border-radius: 0px;
        }
        // 内层轨道 
        ::-webkit-scrollbar-track-piece{
            border-radius: 4px;
            background: white;
        }
        // 滚的条
        ::-webkit-scrollbar-thumb {
            border-radius: 0px;
            background-color: #e7e7e7;
        }
    }
</style>