<template>
    <div class="topbar">
        <div class="topbar-inner">
            <img class="logo" src="../../assets/humanResources/anzhi_Logo.png" alt="">
            <div class="line"></div>
            <div class="title">安知人力资源服务平台</div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"Topbar",
        data(){
            return{

            }
        }
    }
</script>

<style scoped lang="less">
    .topbar{
        width: 100%;
        height: 3.375rem;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        .topbar-inner{
            width: 75rem;
            margin: 0 auto;
            display: flex;
            align-items: center;
            .logo{
                height: 100%;
                width: 47px;
            }
            .line{
                margin: 0 .875rem;
                width: 1px;
                height: 1rem;
                background-color: #999999;
            }
            .title{
                font-size: .875rem;
                font-weight: 600;
                color: #333333;
            }
        }
    }
</style>