<template>
    <div class="footbar">
        <div>您对安知平台有任何建议或意见请联系我们: 0335-8511096</div>
        <div>Copyright @ 2022 安知平台 京ICP备19021711号-2&nbsp;&nbsp;&nbsp;&nbsp;京公网安备11010802030034号&nbsp;&nbsp;&nbsp;&nbsp;(京) 人服证字[2022] 第0600010423号</div>
        <div>北京市海淀区人力资源和社会保障局监督</div>
    </div>
</template>

<script>
    export default {
        name:"Footbar",
        data(){
            return{
                
            }
        }
    }
</script>

<style scoped lang="less">
    .footbar{
        width: 100%;
        height: 7.5rem;
        background-color: #F5F5F5;
        font-size: .875rem;
        font-weight: 400;
        color: #999999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>